import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { data } from "./data";
import { LineChart } from "./LineChart";

const root = ReactDOM.createRoot(document.getElementById('graph1'));
root.render(

    <LineChart data={data} width={400} height={400} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
